<template>
  <v-navigation-drawer
    v-model="showNav"
    app
    class="pb-12"
    color="bgSecondary"
  >
    <v-list dense>
      <!-- FLOPPZ image & twitch link -->
      <v-list-item href="https://twitch.tv/floppz" style="height: 64px" target="_blank">
        <v-list-item-content>
          <v-list-item-title>
            <v-card-title class="white--text ml-n4">
              <v-avatar size="56">
                <img
                  alt="Floppz"
                  src="/floppz_img.png"
                >
              </v-avatar>
              <div class="ml-3 mt-2">
                <div class="text-uppercase">Floppz</div>
                <div class="mt-n3" style="font-size: x-small">(auf Twitch)</div>
              </div>
            </v-card-title>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- PAGE navigation -->
      <v-list-item
        v-for="menuItem in allowedMenuItems"
        :key="menuItem.text"
        :to="menuItem.link"
      >
        <v-list-item-icon>
          <v-icon v-if="menuItem.iconClass" :class="menuItem.iconClass">{{ menuItem.icon }}</v-icon>
          <v-icon v-else-if="menuItem.class" :class="menuItem.class">{{ menuItem.icon }}</v-icon>
          <v-icon v-else>{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-if="menuItem.class" :class="menuItem.class">{{ menuItem.text }}</v-list-item-title>
          <v-list-item-title v-else>{{ menuItem.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TO MERCH -->
      <v-list-item @click="toMerch">
        <v-list-item-icon>
          <v-icon>mdi-cart-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Floppz Merch
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TO DONATION -->
      <v-list-item :to="{ name: 'Trinkgeld' }" class="donateMenu">
        <v-list-item-icon>
          <v-icon>mdi-currency-eur</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Trinkgeld
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Contact -->
      <v-list-item :to="{ name: 'Contact' }">
        <v-list-item-icon>
          <v-icon>mdi-card-account-mail</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Kontakt
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TO HELP & PRIVACY -->
      <v-list-item :to="{ name: 'Help' }">
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Hilfe / Datenschutz
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- TO MOD MENU -->
      <v-list-item
        v-if="userIsMod"
        :to="{ name: 'ModMenu' }"
        class="modMenu"
      >
        <v-list-item-icon>
          <v-icon>mdi-shield-crown-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Admin Panel
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-show="userIsMod"></v-divider>
      <!-- TWITCH login / profil -->
      <v-list-item
        class="twitchLogin"
        @click="handleTwitchLogin"
      >
        <v-list-item-icon>
          <v-icon color="twitch">mdi-twitch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="!user.isLoggedIn">
            Login with twitch
          </v-list-item-title>
          <v-list-item-title v-else>
            {{ user.userData.display_name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TWITCH logout -->
      <v-list-item
        v-show="user.isLoggedIn"
        class="twitchLogout"
        @click="toTwitchLogout"
      >
        <v-list-item-icon>
          <v-icon color="error">mdi-twitch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- IMPRESSUM -->
    <v-list class="impressum" dense>
      <v-divider></v-divider>
      <v-list-item :to="{ name: 'Impressum'}">
        <v-list-item-icon>
          <v-icon>mdi-information-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Impressum
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { userStore } from '@/store/user'
import { globStore } from '@/store/glob'

export default {
  name: 'FloppzNavigation',
  setup() {
    const user = userStore()
    const glob = globStore()
    return {
      user,
      glob
    }
  },
  data() {
    return {
      showNav: true,
      loginDisabled: false,
      menuItems: [
        {
          text: 'Wichtige Informationen',
          icon: 'mdi-information',
          link: { name: 'Info' },
          class: 'error--text'
        },
        {
          text: 'Floppz Foundation (Info)',
          icon: 'mdi-bullhorn-outline',
          link: { name: 'FloppzFoundation' },
          class: 'info--text'
        },
        {
          text: 'Adventskalender',
          icon: 'mdi-hexagram-outline',
          link: { name: 'AdventsKalender' },
          class: 'rainbow--text',
          iconClass: 'yellow--text',
          validFrom: '2024-12-01',
          validTill: '2025-02-01'
        },
        {
          text: 'Regelwerke',
          icon: 'mdi-clipboard-list-outline',
          link: { name: 'Guidelines' }
        },
        {
          text: 'User Befehle',
          icon: 'mdi-account',
          link: { name: 'UserCommands' }
        },
        {
          text: 'Chat Spiele',
          icon: 'mdi-forum-outline',
          link: { name: 'ChatGames' }
        },
        {
          text: 'Stream Spiele',
          icon: 'mdi-controller-classic',
          link: { name: 'PlayedGames' }
        },
        {
          text: 'Droppz / Foggz',
          icon: 'mdi-periodic-table',
          link: { name: 'Droppz_Foogz' }
        },
        {
          text: 'Aktien',
          icon: 'mdi-chart-line',
          link: { name: 'Shares' }
        },
        {
          text: 'Song (Datenbank)',
          icon: 'mdi-music',
          link: { name: 'Songs' }
        },
        {
          text: 'Marbles GP',
          icon: 'mdi-google-earth',
          link: { name: 'MarblesGrandPrix' }
        }
      ]
    }
  },
  computed: {
    userIsMod() {
      return this.glob.getUserIsMod(this.user.userData.id)
    },
    allowedMenuItems() {
      const allowedItems = []
      this.menuItems.forEach(item => {
        if (!item.validFrom && !item.validTill) {
          allowedItems.push(item)
          return
        }
        const validFrom = new Date() >= new Date(item.validFrom)
        const validTill = new Date() <= new Date(item.validTill)
        if (item.validFrom && !item.validTill && validFrom) {
          allowedItems.push(item)
        }
        else if (!item.validFrom && item.validTill && validTill) {
          allowedItems.push(item)
        }
        else if (item.validFrom && item.validTill && validFrom && validTill) {
          allowedItems.push(item)
        }
      })
      return allowedItems
    }
  },
  methods: {
    showNavigation(value) {
      this.showNav = value
    },
    toMerch() {
      window.open('https://shop.floppz.de/', '_blank')
    },
    toTwitchLogout() {
      this.user.logoutTwitch()
    },
    handleTwitchLogin() {
      if (this.user.isLoggedIn) {
        if (this.$router.currentRoute.name !== 'UserProfile') {
          this.$router.push({ name: 'UserProfile' })
        }
      }
      else {
        this.user.loginTwitch()
      }
    }
  }
}
</script>

<style scoped>
.img-inline {
  display: inline-block;
}

.modMenu,
.donateMenu {
  color: #2196f3;
}

.modMenu:hover,
.donateMenu:hover {
  background-color: #2196f3;
  color: #ffffff;
  cursor: pointer;
}

.twitchLogin:hover {
  background-color: #772ce8;
  cursor: pointer;
}

.twitchLogout:hover {
  background-color: #db5754;
  cursor: pointer;
}

.impressum {
  display: block;
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}

.rainbow--text {
  background-image: linear-gradient(to right, red, lime, dodgerblue, deeppink, indigo, deeppink);
  -webkit-background-clip: text;
  color: transparent;
}
</style>