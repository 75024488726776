<template>
  <!--  <div v-show="user.isMod">-->
  <div>
    <v-row>
      <v-col>
        <v-btn-toggle
          v-model="selectedButton"
          shaped
        >
          <v-btn
            color="primary"
            @click="loadSongCheck"
          >
            Songcheck
          </v-btn>
          <v-btn
            color="primary"
            @click="loadSecondScreen"
          >
            SecondScreen
          </v-btn>
          <v-btn
            color="primary"
            @click="loadWatchTime"
          >
            Watchtime
          </v-btn>
          <v-btn
            color="primary"
            @click="loadShortLink"
          >
            Shortlink
          </v-btn>
          <v-btn
            color="primary"
            @click="loadPots"
          >
            Pots
          </v-btn>
          <v-btn
            color="primary"
            @click="loadCalendar"
          >
            Kalender
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="selection === 'watchtime'"
        cols="12"
      >
        <watch-time @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-if="selection === 'shortlink'"
        cols="12"
      >
        <short-link @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-if="selection === 'pots'"
        cols="12"
      >
        <pots-info @updateMounted="updateMounted" />
      </v-col>
      <v-col
        v-if="selection === 'calendar'"
        cols="12"
      >
        <iframe :src="iframeSrc" style="max-height: calc(100dvh - 170px)">
          Dein Browser mag keine iFrames :(
        </iframe>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <iframe :src="iframeSrc" style="max-height: calc(100dvh - 170px)">
          Dein Browser mag keine iFrames :(
        </iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { userStore } from '@/store/user'
import { globStore } from '@/store/glob'
import WatchTime from '@/components/admin/WatchTime.vue'
import ShortLink from '@/components/admin/ShortLink.vue'
import PotsInfo from '@/components/admin/PotsInfo.vue'

export default {
  name: 'ModMenu',
  components: { PotsInfo, ShortLink, WatchTime },
  setup() {
    return {
      user: userStore(),
      glob: globStore()
    }
  },
  data() {
    return {
      selection: null,
      selectedButton: null,
      iframeSrc: '',
      selectionMap: {
        'songcheck': this.loadSongCheck
      }
    }
  },
  methods: {
    loadSongCheck() {
      this.selection = 'songcheck'
      const userId = this.user.userData.id
      this.iframeSrc = `${process.env.VUE_APP_WEB_SERVER}/rewritecybex/floppz/songcheck.cgi?UID=${userId}`
    },
    loadSecondScreen() {
      this.selection = 'secondScreen'
      const userId = this.user.userData.id
      this.iframeSrc = `${process.env.VUE_APP_WS_SERVER}/second?uid=${userId}`
    },
    loadWatchTime() {
      this.selection = 'watchtime'
      this.iframeSrc = ''
    },
    loadShortLink() {
      this.selection = 'shortlink'
      this.iframeSrc = ''
    },
    loadPots() {
      this.selection = 'pots'
      this.iframeSrc = ''
    },
    loadCalendar() {
      this.selection = 'calendar'
      this.iframeSrc = 'https://calendar.google.com/calendar/embed?ctz=Europe%2FBerlin&mode=MONTH&src=YmU0OGUxODVlNTFmZDMzY2M1MzBlMzkyMzc0ZTQ4OTNmNGNiODNjMGQ1YTlkM2RmNzI3NTFmNDRlMmI4NzBjN0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&showTitle=0&showPrint=0&showTz=0'
    },
    updateMounted() {
      this.$emit('updateMounted')
    }
  },
  mounted() {
    this.updateMounted()
  },
  async created() {
    if (!this.glob.admin.length) {
      // await this.glob.fetchGlobData()
    }
    const pageParam = this.$route.query.page
    if (pageParam) {
      this.selection = pageParam
      if (this.selectionMap[pageParam]) {
        this.selectionMap[pageParam]()
      }
    }
  }
}
</script>

<style scoped>
iframe {
  position: relative;
  width: 100%;
  height: 100dvh;
  border: none;
}
</style>